<template>
  <div>
    <HeaderPublic> </HeaderPublic>
    <div class="landing-content">
      <div class="landing-hero">
        <div class="landing-block-01">
          <div class="landing-block-01-copy">
            <h1 class="hero-title">{{ lang_stanza.college_and_career }}</h1>
            <div class="landing-hero-image-responsive">
              <img src="../assets/Hero_image.png" alt="mobile hero image" />
            </div>
            <div class="landing-hero-image">
              <img
                src="../assets/Hero_image_mobile.png"
                alt="mobile hero image"
              />
            </div>
            <br />
            <p class="hero-text">
              {{ lang_stanza.feel_confident }}
            </p>
            <router-link
              id="getting-started-button"
              tag="b-button"
              :to="{ path: `/${lang_info.lang_code}/registration` }"
              >{{ lang_stanza.get_started }}
              <img
                class="white-arrow"
                src="../assets/Button_arrow.png"
                alt="white arrow"
              />
            </router-link>
          </div>
        </div>
      </div>
      <div class="under-hero landing-container">
        <div class="powered-by">Powered by</div>
        <div class="powered-by-icons">
          <img
            src="../assets/THECB-logo-primary.svg"
            alt="Texas Higher Education Coordinating Board Logo"
          />
          <img src="../assets/TXOC logo.svg" alt="Texas On Course Logo" />
          <img
            src="../assets/TWC logo.svg"
            alt="Texas Workforce Commission Logo"
          />
        </div>
        <div class="row">
          <div class="families">
            <img :src="switchFamilyImage()" alt="family photo" />
            <div class="tool-text">
              <div class="tool-title">The ultimate tool for families</div>
              <div class="tool-list">
                <div class="tool-list-item">
                  <img
                    src="../assets/TRT_SITE_Icon_Travel_Geo_Circ_Green.png"
                    alt="Geo tag icon"
                  />
                  <p>
                    Get resources that match your teen’s current grade level
                  </p>
                </div>
                <div class="tool-list-item">
                  <img
                    src="../assets/TRT_SITE_Icon_Persons_Green.png"
                    alt="People icon"
                  />
                  <p>
                    Find the support they need, with tons of resources for
                    students with specific disabilities
                  </p>
                </div>
                <div class="tool-list-item">
                  <img
                    src="../assets/TRT_SITE_Icon_Travel_Sign_2_Green.png"
                    alt="Travel sign icon"
                  />
                  <p>
                    Learn about all their options, including joining the
                    workforce and living independently
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="row landing-steps-row">
          <div class="col-md-4 gx-5">
            <div class="card landing-steps">
              <div class="card-body">
                <img
                  src="../assets/TRT_SITE_Icon_Travel_Geo_Circ_Orange.png"
                  alt="Geo tag icon"
                />
                <h5 class="card-title landing-steps-title">
                  {{ lang_stanza.register }}
                </h5>
                <p class="card-text landing-steps-text">
                  {{ lang_stanza.quick_signup }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-4 gx-5">
            <div class="card landing-steps">
              <div class="card-body">
                <img
                  src="../assets/TRT_SITE_Icon_Travel_Sign_2_Yellow.png"
                  alt="Travel sign icon"
                />
                <h5 class="card-title landing-steps-title">
                  {{ lang_stanza.customize }}
                </h5>
                <p class="card-text landing-steps-text customize">
                  {{ lang_stanza.grades_and_interests }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-4 gx-5">
            <div class="card landing-steps">
              <div class="card-body">
                <img
                  src="../assets/TRT_SITE_Icon_Persons_Green.png"
                  alt="People icon"
                />
                <h5 class="card-title landing-steps-title">
                  {{ lang_stanza.sit_back }}
                </h5>
                <p class="card-text landing-steps-text">
                  {{ lang_stanza.email_notifications }}
                </p>
              </div>
            </div>
          </div>
        </div> -->
        <h1 class="landing-header-1">{{ lang_stanza.what_youll_find }}</h1>
        <div class="under-header-1">
          Take advantage of great resources for students transitioning out of
          high school: for those with and without disabilities!
        </div>
        <div class="row">
          <div class="dashboard-cards">
            <img :src="switchDashboardImage()" alt="dashboard cards graphic" />
            <div class="graphic-text">
              <div class="graphic-title">Tailored to you</div>
              <div class="graphic-blurb">
                Sign up for access to resources based on your teen’s grade level
                and interests. You can choose multiple grades and topics when
                you register.
              </div>
              <router-link
                id="sign-up-button"
                tag="b-button"
                :to="{ path: `/${lang_info.lang_code}/registration` }"
                >{{ lang_stanza.sign_up }}
                <img
                  class="white-arrow"
                  src="../assets/Button_arrow.png"
                  alt="white arrow"
                />
              </router-link>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="action-plan-cards">
            <div class="graphic-text">
              <div class="graphic-title">Stay on track</div>
              <div class="graphic-blurb">
                Want to help your teen plan their future, but don’t know where
                to start? Tomorrow Ready Texas offers step-by-step action plans
                with helpful articles and activities to keep you organized.
              </div>
              <router-link
                id="get-started-button"
                tag="b-button"
                :to="{ path: `/${lang_info.lang_code}/registration` }"
                >{{ lang_stanza.get_started }}
                <img
                  class="white-arrow"
                  src="../assets/Button_arrow.png"
                  alt="white arrow"
                />
              </router-link>
            </div>
            <img :src="switchActionImage()" alt="action plan cards graphic" />
          </div>
        </div>
        <div class="row">
          <div class="article-cards">
            <img :src="switchArticlesImage()" alt="articles graphic" />
            <div class="graphic-text">
              <div class="graphic-title">The support you need</div>
              <div class="graphic-blurb">
                The amount of information out there can be overwhelming. Find
                reliable career and college planning materials in one place for
                transitioning students
                <span class="tooltip-icon"
                  ><img
                    src="../assets/Info-circle.svg"
                    alt="tooltip icon"
                    v-b-tooltip.hover="{ customClass: 'tooltip-class' }"
                    title="Transitioning students are typically students with
                      disabilities; however, all students can benefit from
                      information about transitioning to college or other
                      post-secondary career preparation. Resources about how to
                      live independently, money management, and other areas can
                      help them successfully become independent adults."
                  />
                  <!-- <span class="tooltip-text"
                    >
                    <img src="../assets/Info-circle.svg" alt="tooltip icon" />
                    <p>
                      Transitioning students are typically students with
                      disabilities; however, all students can benefit from
                      information about transitioning to college or other
                      post-secondary career preparation. Resources about how to
                      live independently, money management, and other areas can
                      help them successfully become independent adults.
                    </p></span
                  > -->
                </span>
                and others. Check out one of our resources before signing up.
              </div>
              <router-link
                id="read-the-article"
                tag="b-button"
                :to="{
                  path: `/${lang_info.lang_code}/article/12-college-requesting-accommodations`,
                }"
                >{{ lang_stanza.find_resources }}
                <img
                  class="white-arrow"
                  src="../assets/Button_arrow.png"
                  alt="white arrow"
                />
              </router-link>
            </div>
          </div>
        </div>
        <div class="landing-header-2">Tomorrow Ready Texas covers it all</div>
        <Carousel :slides="slides"></Carousel>
        <div class="VR">
          <div class="VR-title">
            Tomorrow Ready Texas receives support from the Vocational
            Rehabilitations (VR) Program
          </div>
          <div class="VR-text">
            These activities are financed under the TWC Federal Vocational
            Rehabilitation grant. For the Federal fiscal year 2022 (October 1,
            2021, through September 30, 2022), TWC anticipates expending
            $242,743,967 in Federal Vocational Rehabilitation funds. Funds
            appropriated by the State pay a minimum of 21.3% of the total costs
            ($65,698,117) under the Vocational Rehabilitation program. Revised
            June 2022.
          </div>
        </div>

        <!-- <CCarousel controls indicators>
  <CCarouselItem>
    <img class="d-block w-100" src="/images/vue.jpg" alt="slide 1"/>
    <CCarouselCaption class="d-none d-md-block">
      <h5>First slide label</h5>
      <p>Some representative placeholder content for the first slide.</p>
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <img class="d-block w-100" src="/images/react.jpg" alt="slide 2"/>
    <CCarouselCaption class="d-none d-md-block">
      <h5>First slide label</h5>
      <p>Some representative placeholder content for the first slide.</p>
    </CCarouselCaption>
  </CCarouselItem>
  <CCarouselItem>
    <img class="d-block w-100" src="/images/angular.jpg" alt="slide 3"/>
    <CCarouselCaption class="d-none d-md-block">
      <h5>First slide label</h5>
      <p>Some representative placeholder content for the first slide.</p>
    </CCarouselCaption>
  </CCarouselItem>
</CCarousel> -->
        <!-- <div class="card landing-card">
          <h5 class="card-title ct1 landing-card-title">
            {{ lang_stanza.college_planning }}
          </h5>
          <img
            class="landing-card-image"
            src="../assets/TRT_Homepage_Alts_College.jpg"
            alt="college planning landing card image"
          />
          <div class="card-body">
            <h5 class="card-title ct2 landing-card-title">
              {{ lang_stanza.college_planning }}
            </h5>
            <p class="card-text landing-card-text">
              {{ lang_stanza.stay_on_course }}
            </p>
          </div>
        </div> -->
        <!-- <div class="card landing-card">
          <h5 class="card-title ct1 landing-card-title">
            {{ lang_stanza.jobs_and_careers }}
          </h5>
          <img
            class="landing-card-image"
            src="../assets/TRT_Homepage_Alts_Jobs-Career.jpg"
            alt="jobs and careers landing card image"
          />
          <div class="card-body">
            <h5 class="card-title ct2 landing-card-title">
              {{ lang_stanza.jobs_and_careers }}
            </h5>
            <p class="card-text landing-card-text">
              {{ lang_stanza.information }}
            </p>
          </div>
        </div> -->
        <!-- <div class="card landing-card">
          <h5 class="card-title ct1 landing-card-title">
            {{ lang_stanza.financial_wellness }}
          </h5>
          <img
            class="landing-card-image"
            src="../assets/TRT_Homepage_Alts_Financial-wellness.jpg"
            alt="financial wellness landing card image"
          />
          <div class="card-body">
            <h5 class="card-title ct2 landing-card-title">
              {{ lang_stanza.financial_wellness }}
            </h5>
            <p class="card-text landing-card-text">
              {{ lang_stanza.financial_knowledge }}
            </p>
          </div>
        </div> -->
        <!-- <div class="card landing-card">
          <h5 class="card-title ct1 landing-card-title">
            {{ lang_stanza.military }}
          </h5>
          <img
            class="landing-card-image"
            src="../assets/TRT_Homepage_Alts_Military.jpg"
            alt="military landing card image"
          />
          <div class="card-body">
            <h5 class="card-title ct2 landing-card-title">
              {{ lang_stanza.military }}
            </h5>
            <p class="card-text landing-card-text">
              {{ lang_stanza.interest_in_military }}
            </p>
          </div>
        </div> -->
        <!-- <div class="card landing-card">
          <h5 class="card-title ct1 landing-card-title">
            {{ lang_stanza.adult_living }}
          </h5>
          <img
            class="landing-card-image"
            src="../assets/TRT_Homepage_Alts_Life-skills.jpg"
            alt="life skills landing card image"
          />
          <div class="card-body">
            <h5 class="card-title ct2 landing-card-title">
              {{ lang_stanza.adult_living }}
            </h5>
            <p class="card-text landing-card-text">
              {{ lang_stanza.success_after_high_school }}
            </p>
          </div>
        </div> -->
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Carousel from "../components/carousel/carouselNew.vue";

export default {
  title: "Tomorrow Ready Texas",
  name: "Landing",
  components: { Carousel },
  data() {
    return {
      heroImage: 'url("../assets/TRT_HEROIMAGE.svg")',
      lang_stanza_key: "landing",
      lang_stanza: {},
      lang_info: {},
      slides: [
        {
          url: require("../assets/8_life-skills.png"),
          title: "Life Skills",
          underline: "orange",
          blurb:
            "Success after high school depends on a lot more than just adhering to deadlines and filling out applications. Tomorrow Ready Texas includes comprehensive information on topics including social and communications skills, safety, managing conflict, community mobility , health and nutrition, building a support system, contributing to a household, and more",
        },
        {
          url: require("../assets/11_college.png"),
          title: "College Planning",
          underline: "yellow",
          blurb:
            "Get and stay on course for college. Depending on your child’s grade level, get information, resources, suggestions, and to-dos regarding graduation plans, different college options,  college costs, admissions tests, college applications, financial aid, and more.",
        },
        {
          url: require("../assets/Carousel-Financial-Wellness.jpeg"),
          title: "Financial Wellness",
          underline: "green",
          blurb:
            "Support your child’s financial knowledge and well being. Tomorrow Ready Texas provides comprehensive information to help your child become and remain financially responsible. It also helps you understand how you and your child can save and pay for college, with an included focus on financial aid and scholarships.",
        },
        {
          url: require("../assets/Carousel-Military-Readiness.jpeg"),
          title: "Military Readiness",
          underline: "blue",
          blurb:
            "If your child is interested in the military, Tomorrow Ready Texas has comprehensive information on military branches and occupations. Understand how to prepare for the ASVAB, a test that measures your child’s potential for success in military training. Topics also include Junior ROTC programs, military academies, and enlistment.",
        },
        {
          url: require("../assets/Carousel-Jobs-and-Careers.jpg"),
          title: "Jobs & Careers",
          underline: "purple",
          blurb:
            "Tomorrow Ready Texas includes information about soft skills, resumes, applying for jobs, job applications, interviews, and more. Become an expert on endorsements and help your 8th grader explore and choose one.",
        },
      ],
    };
  },
  async created() {
    this.lang_info = await this.getLangInfo();
    this.lang_stanza = await this.getLangStanza({
      lang_code: this.lang_info["lang_code"],
      lang_stanza_key: this.lang_stanza_key,
    });

    if (this.lang_info.lang_code === "en") {
      document.documentElement.setAttribute("lang", "en");
    } else {
      document.documentElement.setAttribute("lang", "es");
    }

    document.body.style.backgroundColor = "white";

    this.lang_info = await this.getLangInfo();
    this.lang_stanza = await this.getLangStanza({
      lang_code: this.lang_info["lang_code"],
      lang_stanza_key: this.lang_stanza_key,
    });
  },
  methods: {
    ...mapActions("language", ["getLangInfo", "getLangStanza"]),
    switchFamilyImage() {
      let userAgentString = navigator.userAgent;
      let chromeAgent = userAgentString.indexOf("Chrome") > -1;
      let safariAgent = userAgentString.indexOf("Safari") > -1;
      let img = "Family-photo";
      if (chromeAgent && safariAgent) {
        return `${require("../assets/" + img + ".webp")}`;
      } else {
        return `${require("../assets/" + img + ".png")}`;
      }
    },
    switchDashboardImage() {
      let userAgentString = navigator.userAgent;
      let chromeAgent = userAgentString.indexOf("Chrome") > -1;
      let safariAgent = userAgentString.indexOf("Safari") > -1;
      let img = "Dashboard_cards";
      if (chromeAgent && safariAgent) {
        return `${require("../assets/" + img + ".webp")}`;
      } else {
        return `${require("../assets/" + img + ".png")}`;
      }
    },
    switchActionImage() {
      let userAgentString = navigator.userAgent;
      let chromeAgent = userAgentString.indexOf("Chrome") > -1;
      let safariAgent = userAgentString.indexOf("Safari") > -1;
      let img = "Action_plan_cards";
      if (chromeAgent && safariAgent) {
        return `${require("../assets/" + img + ".webp")}`;
      } else {
        return `${require("../assets/" + img + ".png")}`;
      }
    },
    switchArticlesImage() {
      let userAgentString = navigator.userAgent;
      let chromeAgent = userAgentString.indexOf("Chrome") > -1;
      let safariAgent = userAgentString.indexOf("Safari") > -1;
      let img = "Articles_cards";
      if (chromeAgent && safariAgent) {
        return `${require("../assets/" + img + ".webp")}`;
      } else {
        return `${require("../assets/" + img + ".png")}`;
      }
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
body {
  background: url("../assets/TRT_heroimage.png");
}

// .tooltip-class {
//   background-color: purple !important;
//   max-width: 500px !important;
// }

.tooltip-class::v-deep .tooltip-inner {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  font-size: 15px !important;
  line-height: 24px;
  color: #000000 !important;
  max-width: 548px !important;
  min-height: max-content;
  background: rgba(246, 246, 246, 1) !important;
  opacity: 1 !important;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: 16px 16px 16px 2px;
}

.tooltip-class::v-deep div.arrow {
  display: none;
}

// #__bv_tooltip_20__ {
//   background-color: purple !important;
// }

.landing-hero {
  background-image: url("../assets/Hero_image.png");
  height: 820px;
  background-color: #fdfdfd;
}

.landing-hero-image {
  display: none;
  visibility: hidden;
}

.landing-hero-image-responsive {
  display: none;
}

.hero-title {
  color: #030303;
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 64px;
  width: 650px;
  text-align: left;
  margin-bottom: 40px;
}

.extend-bg {
  background-color: #fdfdfd;
}

.powered-by {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-top: 50px;
}

.powered-by-icons {
  display: grid;
  grid-template-columns: 148.57px 148.57px 148.57px;
  gap: 16px;
  justify-content: center;
  margin-left: 15px;
  margin-bottom: 35px;
}

.powered-by-icons img {
  width: 148.5px;
}

.families {
  display: grid;
  grid-template-columns: 615px 733px;
  gap: 85px;
}

.families > img {
  width: 615px;
}

.tool-title {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 56px;
  color: #025182;
  margin-top: 125px;
  text-align: left;
  margin-bottom: 24px;
}

.tool-list-item {
  display: flex;
  margin-bottom: 24px;
  align-items: center;
}

.tool-list-item > img {
  width: 40px;
  height: 40px;
}

.tool-list-item:nth-of-type(3) > img {
  margin-bottom: 12px;
}

.tool-list-item > p {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  text-align: left;
  max-width: 655px;
  margin-left: 16px;
  color: #000000;
}

.landing-steps-title {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #025182;
  text-align: left;
  margin-top: 16px;
  margin-bottom: 8px;
}

.landing-steps {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 360px;
  height: 260px;
  background: #ffffff;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: 12px;
}

.landing-steps img {
  width: 64px;
  height: 64px;
}

.landing-steps-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 24px;
  color: #3a3a3a;
  text-align: left;
  width: 296px;
}

.landing-steps-text.customize {
  height: 175px;
}

.landing-container {
  max-width: 1275px !important;
}

.hero-text {
  color: #3a3a3a;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  text-align: left;
  width: 556px;
}

#getting-started-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 24px 32px;
  gap: 8px;
  position: absolute;
  width: 240px;
  height: 68px;
  left: 201px;
  // top: 561px;
  background: #036cae;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
}

.white-arrow {
  width: 44px;
  margin-left: 8px;
}

#getting-started-button:hover {
  background-color: #047ac3;
}

#getting-started-button:active {
  background-color: #036cae !important;
  border-color: #036cae !important;
}

#getting-started-button:focus {
  box-shadow: none !important;
}

.landing-card:nth-of-type(6) {
  margin-bottom: 400px !important;
}

.landing-blurb {
  max-width: 1181px;
  margin-top: 13px;
  margin-bottom: 97px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 16px;
}

.landing-card-title {
  color: #0f0202;
  font-family: Lato;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 40px;
  text-align: left;
}

.landing-card-text {
  color: #666666;
  font-family: Lora;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 28px;
  text-align: left;
  min-height: 168px;
}

.landing-header-1 {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 56px;
  color: #025182;
  margin-top: 71px;
  margin-bottom: 16px;
}

.under-header-1 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  color: #000000;
  margin-bottom: 72px;
}

.dashboard-cards {
  display: grid;
  grid-template-columns: 750px 552px;
  gap: 85px;
}

.dashboard-cards > img {
  width: 798.1px;
}

.dashboard-cards .graphic-text {
  text-align: left;
}

.dashboard-cards .graphic-title {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  color: #025182;
  margin-top: 133px;
  margin-bottom: 16px;
}

.dashboard-cards .graphic-blurb {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  color: #3a3a3a;
  width: 520px;
}

#sign-up-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 24px 32px;
  gap: 16px;
  position: relative;
  margin-left: 140px;
  width: 240px;
  height: 68px;
  background: #036cae;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  top: 40px;
}

#read-the-article {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 24px 32px;
  gap: 16px;
  position: relative;
  margin-left: 140px;
  width: 240px;
  height: 68px;
  background: #036cae;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  top: 40px;
}

#get-started-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 24px 32px;
  gap: 16px;
  position: relative;
  margin-left: 140px;
  width: 240px;
  height: 68px;
  background: #036cae;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  top: 40px;
  display: block !important;
}

.dashboard-cards #sign-up-button .white-arrow {
  margin-left: 30px;
}

#sign-up-button:hover {
  background-color: #047ac3;
}

#sign-up-button:active {
  background-color: #036cae !important;
  border-color: #036cae !important;
}

#sign-up-button:focus {
  box-shadow: none !important;
}

.action-plan-cards {
  display: grid;
  grid-template-columns: 490px 813px;
  margin-top: 140px;
}

.action-plan-cards > img {
  width: 884px;
  margin-left: -52px;
}

.action-plan-cards .graphic-text {
  text-align: left;
}

.action-plan-cards .graphic-title {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  color: #025182;
  margin-top: 245px;
  margin-bottom: 16px;
}

.action-plan-cards .graphic-blurb {
  width: 520px;
  height: 144px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  color: #3a3a3a;
}

.action-plan-cards #get-started-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 24px 32px;
  gap: 8px;
  position: relative;
  margin-left: 110px;
  width: 240px;
  height: 68px;
  background: #036cae;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  top: 64px;
}

.action-plan-cards #get-started-button:hover {
  background-color: #047ac3;
}

.action-plan-cards #get-started-button:active {
  background-color: #036cae !important;
  border-color: #036cae !important;
}

.action-plan-cards #get-started-button:focus {
  box-shadow: none !important;
}

.article-cards {
  display: grid;
  grid-template-columns: 711px 584px;
  margin-top: 91px;
  gap: 82px;
}

.article-cards > img {
  width: 740.11px;
}

.article-cards .graphic-text {
  margin-left: 29px;
  text-align: left;
}

.article-cards .graphic-title {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  color: #025182;
  margin-top: 269px;
  margin-bottom: 16px;
}

.article-cards .graphic-blurb {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  color: #3a3a3a;
  width: 552px;
}

.tooltip-icon > img {
  width: 17.5px;
  height: 17.5px;
  margin-bottom: 16px;
  margin-left: -5px;
  margin-right: -5px;
}

.tooltip-text {
  display: none;
}

.tooltip-text > img {
  width: 14.58px;
  margin-left: 10.7px;
  margin-top: -20px;
}

.tooltip-inner-text {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #000000 !important;
  width: 500px;
  height: 120px;
}

.tooltip-icon:hover .tooltip-text {
  display: -webkit-box;
  width: 548px;
  height: 152px;
  background: #f6f6f6;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  z-index: 3;
  position: absolute;
  bottom: 1185px;
  padding: 16px 16px 16px 2px;
  left: 765px;
}

.article-cards #read-the-article {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 24px 32px;
  gap: 8px;
  margin-left: 115px;
  position: relative;
  width: 316px;
  height: 68px;
  background: #036cae;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.article-cards #read-the-article:hover {
  background-color: #047ac3;
}

.article-cards #read-the-article:active {
  background-color: #036cae !important;
  border-color: #036cae !important;
}

.article-cards #read-the-article:focus {
  box-shadow: none !important;
}

.landing-header-2 {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 56px;
  color: #025182;
  margin-top: 90px;
  margin-bottom: 40px;
}

.col-md-4 {
  max-width: 400px;
  margin: 11px;
  justify-content: center;
}

.VR-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #030303;
  margin-bottom: 8px;
}

.VR-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #030303;
}

.VR {
  margin-bottom: 772px;
  max-width: 1338px;
  text-align: left;
  margin-left: 83px;
  margin-right: 91px;
}

@media (min-width: 500px) and (max-width: 1342px) {
  // .landing-hero-image {
  //   align-content: flex-start;
  //   display: flex;
  //   padding-left: 20px;
  // }

  .landing-hero {
    background-image: none !important;
    padding-left: 0px !important;
  }

  .hero-title {
    padding-left: 85px;
  }

  .landing-hero-image-responsive {
    display: block;
    // float: left;
    margin-bottom: 30px;
    // padding-left: 85px;
  }

  #getting-started-button {
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 32px !important;
    position: unset;
    float: unset !important;
  }

  .hero-text {
    margin-right: auto;
    margin-left: auto;
    clear: both;
    width: 700px;
  }

  .under-hero {
    top: 1350px !important;
  }

  .powered-by {
    margin-right: 150px;
    margin-left: 160px;
  }

  .families {
    display: block;
    grid-template-columns: none;
    margin-left: auto;
    margin-right: auto;
  }

  .dashboard-cards {
    display: block;
    grid-template-columns: none;
    margin-left: auto;
    margin-right: auto;
  }

  .dashboard-cards .graphic-text {
    margin-left: 50px;
    margin-right: 50px;
    max-width: 700px;
  }

  .dashboard-cards .graphic-blurb {
    width: 700px;
  }

  #sign-up-button {
    margin-left: 0px;
  }

  .action-plan-cards {
    display: block;
    grid-template-columns: none;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
  }

  .action-plan-cards > img {
    order: 1;
    margin-left: 0px;
  }

  .action-plan-cards .graphic-text {
    order: 2;
    margin-left: 50px;
    margin-right: 50px;
  }

  .action-plan-cards .graphic-title {
    margin-top: 45px;
  }

  .action-plan-cards .graphic-blurb {
    width: 700px;
  }

  .action-plan-cards #get-started-button {
    margin-left: 0px;
    top: 30px;
  }

  .article-cards {
    display: block;
    grid-template-columns: none;
    margin-left: auto;
    margin-right: auto;
    margin-top: 150px;
  }

  .article-cards .graphic-title {
    margin-top: 85px;
  }

  .article-cards .graphic-blurb {
    width: 700px;
  }

  .article-cards #read-the-article {
    margin-left: 0px;
  }

  .tooltip-icon:hover .tooltip-text {
    left: 259px !important;
    bottom: 940px !important;
  }

  .landing-header-2 {
    margin-top: 176px;
  }

  .VR {
    margin-bottom: 1414px;
  }
}

@media (min-width: 1001px) {
  .landing-hero {
    width: 100%;
    background-repeat: no-repeat;
    background-position: right;
    position: absolute;
    left: 0;
    right: 0;
    top: 96px;
    padding-left: 85px;
    padding-top: 169px;
  }

  .hero-title {
    line-height: 72px;
  }

  .hero-text {
    text-align: left;
  }

  .landing-hero-image {
    display: none;
    visibility: hidden;
  }

  #getting-started-button {
    float: left;
  }

  .landing-container {
    margin-left: auto;
    margin-right: auto;
  }

  .under-hero {
    position: relative;
    top: 700px;
  }

  .landing-header {
    font-size: 64px;
    max-width: 905px;
  }

  .landing-steps-text {
    font-size: 18px;
  }

  .landing-blurb {
    max-width: 1181px;
  }

  .landing-card {
    flex-direction: row;
    max-height: 440px;
    max-width: 1181px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 5px 15px 0 #e6e9ed;
    border: none;
    margin-bottom: 64px;
    margin-left: 16px;
    margin-right: 16px;
  }

  .landing-card:nth-of-type(6) {
    margin-bottom: 1150px !important;
  }

  .landing-card img {
    width: 30%;
    height: 352px;
    width: 535.63px;
    margin-top: 44px;
    margin-bottom: 44px;
    margin-left: 48.18px;
  }

  .landing-card-title {
    font-size: 40px;
    margin-top: 83px;
    margin-left: 20px;
    margin-bottom: 28px;
  }

  .landing-card-text {
    font-size: 18px;
    margin-left: 20px;
    max-width: 521px;
  }

  .ct1 {
    display: none;
  }

  .row {
    max-width: 100%;
    justify-content: space-evenly;
    margin-right: auto;
    margin-left: auto;
    // margin-left: 9px;
  }
}

@media (min-width: 730px) and (max-width: 890px) {
  .action-plan-cards > img {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .landing-hero-image-responsive > img {
    width: 90%;
  }

  .dashboard-cards > img {
    width: 90%;
  }

  .article-cards > img {
    width: 90%;
  }
}

@media (min-width: 769px) and (max-width: 1000px) {
  //tablet
  .landing-hero {
    background-image: none !important;
    padding-left: 85px;
  }

  .card-body {
    padding: 0;
    margin: 62px 12px;
  }

  .landing-steps > .card-body {
    padding: 0;
    margin: 0px 12px;
    min-height: 400px;
  }

  .landing-block-01-copy {
    text-align: center;
  }

  //-------------------------------------------------------------------------------------------------------------------------------------------------

  .hero-title {
    font-size: 64px;
    max-width: 627px;
    padding-top: 50px;
    margin-right: auto;
  }

  // .landing-hero-image {
  //   display: block;
  //   margin-top: -180px;
  //   min-width: 375px;
  //   min-height: 925px;
  //   background-size: 185%;
  //   background-repeat: no-repeat;
  //   background-position: right;
  // }

  .landing-container {
    margin-left: auto;
    margin-right: auto;
  }

  .under-hero {
    position: relative;
    top: 420px !important;
  }

  .landing-header {
    font-size: 56px;
    max-width: 736px;
    margin: 90px auto;
  }

  .landing-steps {
    min-height: 500px;
  }

  .landing-steps-text {
    font-size: 18px;
  }

  // .landing-header-2 {
  //   font-size: 56px;
  // }

  .landing-card {
    flex-direction: row;
    min-height: 386px;
    max-width: 736px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 5px 15px 0 #e6e9ed;
    border: none;
    margin: 32px auto;
    align-items: stretch;
    flex-shrink: 1;
  }

  .landing-card img {
    max-height: 263px;
    max-width: 391px;
    margin: 62px 12px;
    margin-left: 19px;
  }

  .landing-card-text {
    max-width: 290px;
  }

  .ct1 {
    display: none;
  }

  .row {
    max-width: 100%;
    justify-content: space-evenly;
    margin-right: auto;
    margin-left: auto;
  }

  .col-md-4 {
    max-width: 385px;
    padding: 0;
    display: flex;
    flex: 1 1 100px;
  }

  .landing-card > .card-body {
    max-width: 290px;
  }

  .VR {
    margin-bottom: 492px;
  }
}

@media (min-width: 616px) and (max-width: 768px) {
  //tablet
  .landing-hero {
    background-image: none !important;
  }

  .card-body {
    padding: 0;
    margin: 62px 12px;
  }

  .landing-steps > .card-body {
    padding: 0;
    margin: 0px 12px;
    min-height: 400px;
  }

  .landing-block-01-copy {
    text-align: center;
  }

  //-------------------------------------------------------------------------------------------------------------------------------------------------

  .hero-title {
    font-size: 64px;
    max-width: 627px;
    text-align: center;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
  }

  // .landing-hero-image {
  //   display: block;
  //   margin-top: -150px;
  //   min-width: 375px;
  //   min-height: 705px;
  //   background-size: 185%;
  //   background-repeat: no-repeat;
  //   background-position: right;
  // }

  .under-hero {
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }

  .landing-header {
    font-size: 56px;
    max-width: 736px;
    margin: 90px auto;
  }

  .landing-steps {
    max-height: 400px;
    min-width: 375px;
  }

  .landing-steps-title {
    font-size: 32px;
  }

  // .landing-header-2 {
  //   font-size: 56px;
  // }

  .landing-card {
    flex-direction: row;
    min-height: 386px;
    max-width: 736px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 5px 15px 0 #e6e9ed;
    border: none;
    margin: 32px 16px;
    align-items: stretch;
    flex-shrink: 1;
    justify-items: center;
  }

  .landing-card img {
    max-height: 263px;
    min-width: 270px;
    margin: auto 12px;
    flex-shrink: 3;
  }

  .ct1 {
    display: none;
  }

  .row {
    max-width: 100%;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
  }

  .col-md-4 {
    min-width: 360px;
    padding: 0;
    display: flex;
    flex: 1 1 100px;
  }

  .landing-card > .card-body {
    max-width: 290px;
  }
}

@media (min-width: 640px) and (max-width: 729px) {
  .action-plan-cards > img {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
  }

  .dashboard-cards > img {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
  }

  .article-cards > img {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
  }

  .landing-hero-image-responsive > img {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
  }

  .hero-text {
    max-width: 700px !important;
    width: 95%;
  }

  .dashboard-cards .graphic-blurb {
    width: 90%;
  }

  .dashboard-cards .graphic-text {
    margin-left: 50px;
    margin-right: 30px;
    max-width: 700px;
  }

  .action-plan-cards .graphic-blurb {
    width: 90%;
  }

  .article-cards .graphic-blurb {
    width: 90%;
  }
}

@media (min-width: 481px) and (max-width: 639px) {
  .hero-title {
    width: 100%;
  }

  .landing-hero-image-responsive > img {
    width: 85%;
  }

  .hero-text {
    width: 90%;
  }

  .landing-content .under-hero {
    top: 250px !important;
  }

  .families > img {
    width: 90%;
  }

  .action-plan-cards > img {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
  }

  .action-plan-cards .graphic-blurb {
    width: 100%;
  }

  .dashboard-cards > img {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
  }

  .dashboard-cards .graphic-blurb {
    width: 100%;
  }

  .article-cards > img {
    width: 85%;
  }

  .article-cards .graphic-text {
    margin-left: 50px;
    margin-right: 50px;
  }

  .article-cards .graphic-blurb {
    width: 100%;
  }

  .VR {
    margin-bottom: 350px !important;
  }
}

@media (min-width: 500px) and (max-width: 768px) {
  .under-hero {
    top: 350px !important;
  }

  .VR {
    margin-bottom: 392px;
  }
}

@media (min-width: 376px) and (max-width: 615px) {
  // .landing-hero {
  //   background-image: none !important;
  // }

  .landing-card-title {
    text-align: center;
    margin: 24px auto 16px auto;
  }

  // .landing-steps > .card-body {
  //   padding: 0;
  //   margin: 0px 12px;
  //   min-height: 400px;
  // }

  .landing-block-01-copy {
    text-align: center;
  }

  //-------------------------------------------------------------------------------------------------------------------------------------------------

  .hero-title {
    min-width: 370px;
    text-align: center;
    padding: 28px 0;
  }

  // .landing-hero-image {
  //   display: block;
  //   margin-top: -100px;
  //   min-width: 375px;
  //   min-height: 565px;
  //   background-size: 185%;
  //   background-repeat: no-repeat;
  //   background-position: right;
  // }

  .under-hero {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 225px;
  }

  .landing-header {
    font-size: 50px;
    max-width: 736px;
    margin: 90px auto;
  }

  // .landing-steps {
  //   max-height: 400px;
  //   min-width: 375px;
  // }

  // .landing-steps-title {
  //   font-size: 32px;
  // }

  // .landing-header-2 {
  //   font-size: 50px;
  // }

  .landing-card {
    flex-direction: column;
    min-height: 544px;
    max-width: 346px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 5px 15px 0 #e6e9ed;
    border: none;
    margin-bottom: 64px;
    margin-left: auto;
    margin-right: auto;
  }

  .landing-card img {
    min-width: 315px;
    height: 210px;
    margin: 15px;
  }

  .landing-card-text {
    font-size: 18px;
    min-width: 315px;
  }

  .ct2 {
    display: none;
  }

  .row {
    max-width: 100%;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
  }

  .col-md-4 {
    min-width: 360px;
    padding: 0;
    display: flex;
    flex: 1 1 100px;
  }

  .landing-card > .card-body {
    max-width: 290px;
  }
}

@media (max-width: 480px) {
  //mobile
  .hero-title {
    max-width: 400px;
    height: 168px;
    font-family: "Merriweather";
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 56px;
    text-align: left;
    padding-left: 20px;
    padding-top: 36px;
  }

  .hero-text {
    width: 320px;
    min-height: 144px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    padding-left: 20px;
    padding-top: 15px;
  }

  .landing-hero {
    background-image: none;
    height: 650px;
  }

  .landing-hero-image {
    align-content: flex-start !important;
    display: flex !important;
    padding-left: 20px !important;
    visibility: visible;
    margin-top: 41px;
  }

  #getting-started-button {
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 32px !important;
    position: unset;
    float: unset !important;
  }

  .under-hero {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    top: 100px;
  }

  .powered-by {
    margin-top: 140px;
  }

  .powered-by-icons {
    grid-template-columns: 99.53px 99.53px 99.53px;
    gap: 11px;
  }

  .powered-by-icons img {
    width: 99.53px;
  }

  .row {
    max-width: 100%;
    -webkit-box-pack: center;
    justify-content: center;
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .landing-steps-row .col-md-4 {
    min-width: 295px;
  }

  .under-hero .row {
    overflow: auto;
    flex-wrap: wrap;
    justify-content: flex-start;
    overflow-y: hidden;
  }

  .families {
    display: block;
    grid-template-columns: none;
    margin-left: auto;
    margin-right: auto;
  }

  .families > img {
    max-width: 325px;
  }

  .tool-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    max-width: 332px;
    margin-top: 40px;
  }

  .tool-list-item > img {
    width: 32px;
    height: 32px;
  }

  .tool-list-item > p {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    max-width: 284px;
  }

  .under-header-1 {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    max-width: 328px;
    margin-right: auto;
    margin-left: auto;
  }

  .landing-steps {
    width: 280px;
    height: 280px;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.25);
  }

  .landing-header-1 {
    width: 344px;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    margin: 40px auto 16px auto;
  }

  .card.landing-steps {
    border: none;
    margin: auto;
  }

  .landing-steps-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    padding-left: 30px;
    padding-right: 24px;
  }

  .landing-steps-text {
    font-weight: 400;
    font-size: 14px !important;
    line-height: 24px;
    padding: 8px 24px 34px 30px;
  }

  .dashboard-cards {
    display: block;
    grid-template-columns: none;
    margin-left: auto;
    margin-right: auto;
    height: 625px;
    gap: 0;
  }

  .dashboard-cards > img {
    width: 342px;
    height: 270px;
    margin: 0px auto 0px auto;
  }

  .dashboard-cards .graphic-text {
    margin-left: 32px;
  }

  .dashboard-cards .graphic-title {
    width: 296px;
    height: 36px;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    margin-top: 24px;
  }

  .dashboard-cards .graphic-blurb {
    width: 296px;
    height: 96px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }

  #sign-up-button {
    margin-left: 30px;
    margin-top: -25px;
  }

  .action-plan-cards {
    grid-template-columns: none;
    margin-right: auto;
    margin-left: auto;
    height: 790px;
    margin-top: -95px;
  }

  .action-plan-cards > img {
    width: 344px;
    margin: 0px auto 0px auto;
    position: relative;
    bottom: 215px;
  }

  .action-plan-cards .graphic-title {
    width: 296px;
    height: 36px;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    margin-top: 24px;
  }

  .action-plan-cards .graphic-blurb {
    width: 296px;
    height: 96px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }

  .action-plan-cards .graphic-text {
    margin-left: 32px;
    position: relative;
    top: 475px;
  }

  .action-plan-cards #get-started-button {
    margin-left: 25px;
    margin-top: -10px;
  }

  .article-cards {
    grid-template-columns: none;
    margin-top: 0px;
    margin-right: auto;
    margin-left: auto;
    gap: 10px;
    height: 860px;
  }

  .article-cards > img {
    width: 344px;
    margin-left: 8px;
    margin-top: 70px;
  }

  .article-cards .graphic-text {
    margin-left: 32px;
  }

  .article-cards .graphic-title {
    font-size: 24px;
    line-height: 36px;
    margin-top: -70px;
  }

  .article-cards .graphic-blurb {
    font-size: 14px;
    line-height: 24px;
    width: 296px;
    height: 96px;
  }

  .article-cards #read-the-article {
    margin-left: 0px;
    margin-top: 65px;
  }

  // #read-the-article {
  //     margin-left: 0px;
  //     margin-top: 65px;
  // }

  .landing-header-2 {
    font-size: 24px;
    line-height: 36px;
    width: 333px;
    height: 72px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
  }

  .VR-title {
    font-size: 16px;
    line-height: 24px;
    width: 328px;
    height: 72px;
  }

  .VR-text {
    font-size: 14px;
    line-height: 24px;
    width: 328px;
  }

  .VR {
    margin-bottom: 140px;
    margin-left: 16px;
    margin-right: 16px;
    width: 328px;
  }
}
</style>
