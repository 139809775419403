<template>
  <div id="carousel-container">
    <carousel-controls @prev="prev" @next="next"></carousel-controls>
    <div class="hp-carousel" id="hp-carousel">
      <carousel-item
        class="hp-carousel-inner"
        :id="currentSlide == s_idx ? 'active-slide' : ''"
        :slide="slide"
        v-for="(slide, s_idx) in this.slides"
        :key="s_idx"
      ></carousel-item>
    </div>
    <carousel-indicators
      :slides="slides"
      :count="slides.length"
      :active="currentSlide"
      @switch="switchSlide($event)"
    ></carousel-indicators>
    <!-- <button class="scroll-btn" @click="scroll()">scroll</button> -->
  </div>
</template>

<script>
import CarouselItem from "./CarouselItem.vue";
import CarouselControls from "./CarouselControls.vue";
import CarouselIndicators from "./CarouselIndicators.vue";

export default {
  props: ["slides"],
  components: { CarouselItem, CarouselControls, CarouselIndicators },
  data() {
    return {
      currentSlide: 0,
      slideInterval: null,
      direction: "right",
    };
  },
  mounted() {
    //   this.slideInterval = setInterval(() => {
    //       this.next();
    //   }, 10000);
    const carousel = document.getElementById("hp-carousel");
    carousel.addEventListener("scroll", async (e) => {
      this.currentSlide = await this.swipeAdjust();
    });
  },
  beforeDestroy() {
    clearInterval(this.slideInterval);
  },
  methods: {
    switchSlide(index) {
      const step = index - this.currentSlide;
      if (step > 0) {
        this.next(step);
      } else {
        this.prev(step);
      }
    },
    setCurrentSlide(index) {
      //   this.currentSlide = index;
      this.scroll(index);
    },
    prev(step = -1) {
      const index =
        this.currentSlide > 0
          ? this.currentSlide + step
          : this.slides.length - 1;
      this.setCurrentSlide(index);
      this.direction = "left";
    },
    next(step = 1) {
      const index =
        this.currentSlide < this.slides.length - 1
          ? this.currentSlide + step
          : 0;
      this.setCurrentSlide(index);
      this.direction = "right";
    },
    scroll(slideNum) {
      const element = document.getElementById("hp-carousel");
      let x = element.scrollWidth / this.slides.length;
      element.scrollLeft = x * slideNum;
    },
    async swipeAdjust() {
      let elementW = document.getElementById("hp-carousel").scrollWidth;
      let elementC = document.getElementById("hp-carousel").scrollLeft;
      let curr = isNaN(elementC / elementW) ? 0 : elementC / elementW;
      curr = curr * this.slides.length;
      return Math.round(curr);
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  scroll-behavior: smooth !important;
}
#carousel-container {
  width: 100%;
}

.hp-carousel {
  //   border: 2px solid #f00;
  display: flex;
  flex: 1;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  max-width: 1080px;
  padding-bottom: 17px;
  margin-bottom: 17px;
  margin-left: auto;
  margin-right: auto;
  scroll-snap-type: x mandatory;
}

// hides scrollbar
.hp-carousel::-webkit-scrollbar {
  display: none;
}
// hides scrollbar
.hp-carousel {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.hp-carousel-inner {
  max-width: 100%;
  margin: 0 20px;
  scroll-snap-align: center;
}

#active-slide {
}

@media (min-width: 800px) and (max-width: 1060px) {
  .hp-carousel {
    height: 350px;
  }
  .hp-carousel-inner {
    height: 325px;
    max-width: 96%;
  }
}

// @media (min-width: 731px) {
//   .hp-carousel {
//     height: 295px;
//   }
//   .hp-carousel-inner {
//     height: 280px;
//   }
// }

@media (max-width: 799px) {
  .hp-carousel {
    max-width: 600px;
    justify-content: flex-start;
    align-items: center;
    margin: auto;
    min-height: 280px !important;
  }

  .hp-carousel-inner {
    min-width: 90%;
    min-height: 280px !important;
    justify-items: center;
  }
}
</style>