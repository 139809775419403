<template>
  <transition :name="transitionName">
    <div class="hp-carousel-item" v-show="currentSlide === index">
      <img :src="slide.url" alt="" />
      <div class="slide-text">
        <div class="slide-title">{{ slide.title }}</div>
        <div :class="`underline-${slide.underline}`"></div>
        <div class="slide-blurb">{{ slide.blurb }}</div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: ["slide", "currentSlide", "index", "direction"],
  computed: {
    transitionName() {
      return this.direction === "right" ? "slide-in" : "slide-out";
    },
  },
};
</script>

<style scoped>
.hp-carousel-item {
  /* width: 1040px; */
  height: 296px;
  background: #ffffff;
  opacity: 0.96;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  display: grid;
  grid-template-columns: 256px 656px;
  padding: 52px;
  gap: 24px;
}

.hp-carousel-item img {
  width: 256px;
  height: 192px;
}

.hp-carousel-item .slide-title {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #000000;
  text-align: left;
  margin-bottom: 8px;
}

.hp-carousel-item .underline-orange {
  max-width: 656px;
  height: 4px;
  background: linear-gradient(
    90deg,
    #f25f29 0%,
    rgba(242, 95, 41, 0.5) 44.64%,
    rgba(242, 95, 41, 0.25) 100%
  );
  margin-bottom: 8px;
}

.hp-carousel-item .underline-yellow {
  max-width: 656px;
  height: 4px;
  background: linear-gradient(
    90deg,
    #f2ac29 0%,
    rgba(242, 172, 41, 0.5) 47.62%,
    rgba(242, 172, 41, 0.25) 100%
  );
  margin-bottom: 8px;
}

.hp-carousel-item .underline-green {
  max-width: 656px;
  height: 4px;
  background: linear-gradient(
    90deg,
    #03a66a 0%,
    rgba(3, 166, 106, 0.5) 49.38%,
    rgba(3, 166, 106, 0.25) 100%
  );
  margin-bottom: 8px;
}

.hp-carousel-item .underline-blue {
  max-width: 656px;
  height: 4px;
  background: linear-gradient(
    90deg,
    #0487d9 0%,
    rgba(4, 135, 217, 0.5) 46.88%,
    rgba(4, 135, 217, 0.25) 100%
  );
  margin-bottom: 8px;
}

.hp-carousel-item .underline-purple {
  max-width: 656px;
  height: 4px;
  background: linear-gradient(
    90deg,
    rgba(140, 50, 116, 0.25) 0%,
    #557eba 0%,
    #8c3274 0%,
    rgba(140, 50, 116, 0.5) 50.58%,
    rgba(140, 50, 116, 0.25) 102.36%
  );
  margin-bottom: 8px;
}

.hp-carousel-item .slide-blurb {
  /* min-width: 300px; */
  text-align: left;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #3a3a3a;
}

.slide-text {
  /* max-width: 689px; */
}

.slide-out-enter-active,
.slide-out-leave-active .slide-in-enter-active,
.slide-in-leave-active {
  transition: all 1s ease-in-out;
}

.slide-out-enter-from {
  transform: translateX(-100%);
}

.slide-out-leave-to {
  transform: translateX(100%);
}

.slide-in-enter-from {
  transform: translateX(100%);
}

.slide-in-leave-to {
  transform: translateX(100%);
}

@media (min-width: 800px) and (max-width: 1060px) {
  .hp-carousel-item .underline-orange,
  .hp-carousel-item .underline-yellow,
  .hp-carousel-item .underline-green,
  .hp-carousel-item .underline-blue,
  .hp-carousel-item .underline-purple {
    max-width: 65%;
  }

  .hp-carousel-item .slide-blurb {
    font-size: 16px;
  }
}

@media (min-width: 945px) and (max-width: 1036px) {
  .hp-carousel-item .slide-blurb {
    width: 90%;
  }
}

@media (min-width: 800px) and (max-width: 944px) {
  .hp-carousel-item .slide-blurb {
    width: 65%;
  }
}

@media (max-width: 799px) {
  .hp-carousel-item {
    max-width: 100%;
    height: 560px;
    left: 0;
    grid-template-columns: none;
    padding: 32px;
    gap: 16px;
  }

  .hp-carousel-item .slide-title {
    font-size: 24px;
    line-height: 36px;
  }

  .hp-carousel-item .slide-blurb {
    font-size: 14px;
    line-height: 24px;
  }

  .hp-carousel-item .underline-blue,
  .hp-carousel-item .underline-green,
  .hp-carousel-item .underline-purple,
  .hp-carousel-item .underline-orange,
  .hp-carousel-item .underline-yellow {
    width: 256px;
  }
}
</style>