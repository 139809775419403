<template>
  <div id="controller-container">
    <button @click="$emit('prev')" class="carousel-controls left">
      <img src="../../assets/Left-arrow.svg" alt="previous arrow" />
    </button>
    <button @click="$emit('next')" class="carousel-controls right">
      <img src="../../assets/Right-arrow.svg" alt="next arrow" />
    </button>
  </div>
</template>

<script>
export default {
  emits: ["prev", "next"],
};
</script>


<style lang="scss" scoped>
#controller-container {
  // position: relative;
  max-width: 1080px;
  margin: auto;
}

.carousel-controls {
  border-radius: 50%;
  border: none;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.25);
  background: #ffffff;
  /* position: absolute; */
  height: 40px;
  width: 40px;
  top: calc(100% - 25px);
  color: #333333;
  cursor: pointer;
  z-index: 10;
}

.left {
  position: relative;
  right: 46%;
  top: 150px;
}

.right {
  position: relative;
  left: 46%;
  top: 150px;
}

.right img {
  position: relative;
  right: 13px;
  bottom: 5px;
}

.left img {
  position: relative;
  right: 13px;
  bottom: 5px;
}

@media (max-width: 800px) {
  .right {
    left: 240px;
  }

  .left {
    right: 260px;
  }
}

@media (max-width: 600px) {
  .right {
    left: 40%;
  }

  .left {
    right: 42%;
  }
}

</style>