<template>
  <div class="carousel-indicators">
    <button
      class="carousel-indicator-item"
      :class="{ active: active === index }"
      v-for="(item, index) in count"
      :key="index"
      :id="key"
      @click="scroll(index)"
    ></button>
  </div>
</template>

<script>
export default {
  props: ["count", "active", "slides"],
  methods: {
    scroll(slideNum) {
      this.$emit('switch', slideNum);
      const element = document.getElementById("hp-carousel");
      let x = element.scrollWidth / this.slides.length;
      element.scrollLeft = x * slideNum;
    },
  },
};
</script>

<style scoped>
.carousel-indicators {
  z-index: 10;
  position: relative;
  top: 0px;
  margin-bottom: 83px;
}

.carousel-indicator-item {
  border: none;
  width: 12px;
  height: 12px;
  background-color: #888888;
  border-radius: 50%;
  opacity: 0.5;
  margin: 4px;
  cursor: pointer;
}

.active {
  opacity: 1;
}
</style>