<template>
  <div class="landing-content">
    SITE DOWN
  </div>
</template>

<script>

export default {
  name: "SiteDown",
  data() {
    return {
       lang_stanza_key: 'landing',
       lang_stanza: {},
      lang_info: {}
     };
  },
  async created() {

    this.lang_info = await this.getLangInfo();
    this.lang_stanza = await this.getLangStanza({
      "lang_code": this.lang_info["lang_code"],
      "lang_stanza_key": this.lang_stanza_key
    });

    document.body.style.backgroundColor = "white";
  },
};
</script>

<style lang="scss" scoped>
body {
  background: url("../assets/TRT_heroimage.png");
}

.landing-hero {
  width: 100%;
  height: 726px;
  background-repeat: no-repeat;
  background-position: right;
  position: absolute;
  left: 0;
  right: 0;
  top: 83px;
  padding-left: 109px;
  padding-top: 72px;
}

.hero-title {
  color: #030303;
  font-family: Lato;
  font-size: 72px;
  font-weight: 900;
  letter-spacing: 1.6px;
  line-height: 72px;
  width: 789px;
  text-align: left;
  margin-bottom: 28px;
}

.hero-text {
  color: #666666;
  font-family: Lato;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: left;
  margin-bottom: 73px;
}

#getting-started-button {
  height: 48px;
  width: 243px;
  border-radius: 16px;
  background-color: #f15a2a;
  color: #ffffff;
  font-family: Lato;
  font-size: 19px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  border: none;
  float: left;
}

#getting-started-button:hover {
  background-color: #f1662a;
}

#getting-started-button:active {
  background-color: #e85127 !important;
  border-color: #e85127 !important;
}

#getting-started-button:focus {
  box-shadow: none !important;
}

.landing-checklist {
  height: 356px;
  width: 393px;
  background-color: #FFFFFF;
  box-shadow: 0 8px 24px 0 rgba(179, 179, 179, .15);
  margin-top: 255px;
  padding: 95px 0;
  text-align: left;
}

.landing-checklist > p {
  margin-bottom: 45px;
  color: #3A3A3A;
  font-family: Lato;
  font-size: 18px;
  letter-spacing: 0.5px;
  line-height: 22px;
  margin-left: 88px;
}

.landing-checklist > p > img {
  height: 18px;
  width: 18px;
  margin-right: 4px;
}

.landing-container {
  max-width: 1275px !important;
  margin-left: auto;
  margin-right: auto;
}

.under-hero {
  position: relative;
  top: 1035px;
}

.landing-header {
  color: #030303;
  font-family: Lato;
  font-size: 64px;
  font-weight: bold;
  width: 905px;
  margin-bottom: 90px;
  margin-left: auto;
  margin-right: auto;
}

.landing-steps {
  height: 502px;
  width: 384px;
  border-radius: 10px;
  background-color: #f5f7fa;
  border: none;
}

.card-number {
  color: #ffffff;
  font-family: Lato;
  font-size: 40px;
  font-weight: bold;
  border-radius: 50%;
  background-color: #0d2b5a;
  width: 64px;
  height: 64px;
  margin: auto;
  position: relative;
  top: 75px;
}

.landing-steps-title {
  color: #030303;
  font-family: Lato;
  font-size: 40px;
  font-weight: bold;
  margin-top: 95px;
  margin-bottom: 20px;
}

.landing-steps-text {
  color: #666666;
  font-family: Lora;
  font-size: 18px;
  width: 280px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: 0;
  line-height: 28px;
}

.landing-steps-text.customize {
  height: 175px;
}

.landing-header-2 {
  color: #030303;
  font-family: Lato;
  font-size: 64px;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  margin-top: 139px;
}

.landing-blurb {
  color: #4a4a4a;
  font-family: Lora;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 28px;
  text-align: left;
  margin-top: 13px;
  margin-bottom: 97px;
}

.landing-card {
  flex-direction: row;
  height: 440px;
  width: 1181px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 5px 15px 0 #e6e9ed;
  border: none;
  margin-bottom: 64px;
}

.landing-card:nth-of-type(6) {
  margin-bottom: 1150px !important;
}

.landing-card img {
  width: 30%;
  height: 352px;
  width: 535.63px;
  margin-top: 44px;
  margin-bottom: 44px;
  margin-left: 48.18px;
}

.landing-card-title {
  color: #0f0202;
  font-family: Lato;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 40px;
  text-align: left;
  margin-top: 83px;
  margin-left: 20px;
  margin-bottom: 28px;
}

.landing-card-text {
  color: #848484;
  font-family: Lora;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 28px;
  text-align: left;
  margin-left: 20px;
  height: 168px;
  width: 521px;
}
</style>
